import * as React from "react";
import { gsap } from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";

import arrowDownSvg from "../../images/darkmode/arrow-down.svg";

export default function Hero({ animationEnabled }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const element = ref.current;
    gsap.set(element.querySelectorAll("#main-content .main-div"), { y: 0 });
  }, []);

  React.useEffect(() => {
    if (animationEnabled) {
      const element = ref.current;
      const $mainContent = element.querySelector("#main-content");
      const $heroDescription = element.querySelector("#hero-description");
      gsap.to(element.querySelectorAll("#main-content .main-div"), {
        y: -300,
        scrollTrigger: {
          trigger: $mainContent,
          start: "top top",
          endTrigger: $heroDescription,
          end: "top -=300",
          scrub: true,
        },
      });

      ScrollTrigger.create({
        trigger: $heroDescription,
        start: "top 80%",
        once: true,
        onEnter: () => {
          $heroDescription.classList.add("reveal");
        },
      });
    }
  }, [animationEnabled]);

  return (
    <div ref={ref}>
      <div id="main-content" className="relative hero-background">
        <div className="main-div">
          <div
            id="cta-text-hero"
            className="content-container flex flex-grow flex-col text-center justify-center items-center content-center"
          >
            <h1 className="w-11/12 text-4xl mt-36 md:mt-60 lg:text-5xl xl:text-8xl leading-tight md:w-auto md:whitespace-nowrap">
              Meet the Aluminum Collection.
              <br className="hidden md:block" /> In stock and ready to ship.
            </h1>
            <p className="mt-5 text-white-300 text-lg md:mt-12 md:text-2xl xl:text-4xl">
              Ready to ship inventory available in gray cushions only.
            </p>
            <p className="mt-5 text-white-900 text-lg md:mt-12 md:text-xl">
              Limited inventory available
            </p>
            <div className="animate-bounce mt-10 md:mt-16">
              <img
                className="cursor-pointer"
                src={arrowDownSvg}
                onClick={() => {
                  gsap.to(window, {
                    duration: 0.75,
                    scrollTo: {
                      y:
                        ref.current
                          .querySelector("#cta-text-hero")
                          .getBoundingClientRect().height -
                        (window.innerWidth > 1027 ? 200 : 270),
                    },
                  });
                }}
              />
            </div>
          </div>
          <div
            id="hero-description"
            className="content-container-max relative mt-20 lg:mt-36 xl:mt-56"
          >
            <div className="relative">
              <p className="text-lg text-white-300 md:leading-tight md:text-2xl lg:text-3xl xl:text-5xl xl:leading-tight md:w-7/12">
                <strong className="text-white-900">
                  Sleek and effortlessly modern,
                </strong>{" "}
                the new Aluminum Collection is inspired by the elegant movement
                of luxury automotive design.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
