import * as React from "react";

import spinner from "../../images/shared/outer-logo-spinner-transparent.gif";

export default function Spinner() {
  return (
    <div className="absolute -inset-0 z-10 flex items-center">
      <img className="mx-auto" style={{ width: 60 }} src={spinner} />
    </div>
  );
}
