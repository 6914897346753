import * as React from "react";
import StrongHeader from "../darkmode/strong-header";
import ShopNow from "./shop-now";
import ScrollTrigger from "gsap/ScrollTrigger";

export default function GetYours({ animationEnabled }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (animationEnabled) {
      const element = ref.current;
      const $subheader = element.querySelector(".fade-in p");
      ScrollTrigger.create({
        trigger: $subheader,
        start: "top bottom",
        once: true,
        onEnter: () => {
          element.classList.add("reveal");
        },
      });
    }
  }, [animationEnabled]);

  return (
    <div
      ref={ref}
      className="get-yours content-container-max text-center mb-20"
    >
      <div className="fade-in w-11/12 mx-auto md:w-full">
        <StrongHeader strong="Get" normal="yours" />
        <p className="mt-5 text-white-300 text-lg md:leading-relaxed md:mt-12 md:text-2xl xl:text-4xl">
          In stock and ready to ship today. Get <br />
          yours and enjoy the summer.
        </p>
        <p className="mt-5 text-white-900 text-lg md:mt-12 md:text-xl">
          Limited inventory available
        </p>
      </div>
      <ShopNow />
    </div>
  );
}
